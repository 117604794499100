import { media, sizing } from 'design-system/style';
import { colors } from 'design-system/style/color';
import styled from 'styled-components';

export const ScrollArrowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ScrollArrow = styled.button<{
  isDisabled: boolean;
  variant?: 'primary' | 'secondary';
}>`
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: ${sizing(32)};
  height: ${sizing(32)};
  border: 1px solid
    ${({ variant }) =>
      variant === 'secondary' ? 'transparent' : colors.grays.GRAY50};
  background-color: ${({ theme, variant }) =>
    variant === 'secondary'
      ? theme.colors.background.level1
      : colors.grays.GRAY70};
  transition: opacity 300ms;
  display: none;
  outline: none;
  opacity: ${({ isDisabled, variant }) =>
    variant === 'secondary' && isDisabled ? 0.4 : 1};

  @media screen and (min-width: ${media.queries.TABLET}) {
    display: flex;
    flex-direction: row;
  }

  ${({ variant, theme, isDisabled }) =>
    !isDisabled &&
    `&:hover {
    background-color: ${
      variant === 'secondary'
        ? theme.colors.background.level3
        : colors.grays.GRAY50
    };
  }`}
`;

export const StyledScrollArrowLeft = styled(ScrollArrow)`
  margin-left: ${sizing(16)};
`;

export const StyledScrollArrowRight = styled(ScrollArrow)`
  margin-left: ${sizing(8)};
`;
