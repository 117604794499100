import { sizing, media, animations, utils } from 'design-system/style';
import styled from 'styled-components';
import { BROWSE_GUTTER } from 'components/PageContentWrapper/Styled';
import { CardContainer } from 'components/BrowseRow/CollectionView/CollectionItem/Styled';
import { UserCardContainer } from './renderers/UserCard/Styled';

export const Container = styled.section<{ isCollectionV2: boolean }>`
  position: relative;

  padding-bottom: ${({ isCollectionV2 }) =>
    isCollectionV2 ? '0' : `${sizing(16)}`};

  @media (min-width: ${media.queries.TABLET}) {
    width: 100%;
    padding-bottom: ${({ isCollectionV2 }) =>
      isCollectionV2 ? '0' : `${sizing(16)}`};
  }
`;

export const ScrollRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  padding-top: ${sizing(16)};
  overflow-x: scroll;
  scrollbar-width: none;
  overflow: -moz-scrollbars-none;
  position: relative;

  &::-webkit-scrollbar {
    height: 0 !important;
    width: 0 !important; // hiding scrollbar on chrome, safari & newer versions of opera
  }

  > div {
    flex: 0 0 auto;
  }

  @media (min-width: ${media.queries.TABLET}) {
  }
`;

export const HeroRow = styled.div`
  display: flex;

  // These properties control the mobile overflow/scroll behavior
  // The gutter is added to the width so that cards in this row reach the screen edge
  // The gutter is also added as padding, so that when the user scrolls to the end of the row, the last card sits flush.
  width: calc(100% + ${BROWSE_GUTTER});
  padding-right: ${BROWSE_GUTTER};

  flex-direction: row;
  padding-top: ${sizing(8)};
  overflow-x: scroll;
  scrollbar-width: none;
  overflow: -moz-scrollbars-none;
  position: relative;

  &::-webkit-scrollbar {
    height: 0 !important;
    width: 0 !important; // hiding scrollbar on chrome, safari & newer versions of opera
  }

  @media (min-width: ${media.queries.TABLET}) {
    width: auto;
    padding-right: 0;
  }
`;

export const FullWidthScrollRow = styled(ScrollRow)`
  margin: 0 -18px 0 -18px;
  width: auto;

  @media (min-width: ${media.queries.TABLET}) {
    margin: 0 auto;
    width: 100%;
  }
`;

export const ViewRow = styled.div`
  padding-top: ${sizing(10)};
  padding-bottom: ${sizing(10)};
  padding-right: ${sizing(
    15
  )}; // so the cards dont touch the right side of the screen
  width: 100%;
  overflow-x: scroll;
  scrollbar-width: none;

  @media (min-width: ${media.queries.TABLET}) {
    padding-bottom: ${sizing(15)};
  }

  &::-webkit-scrollbar {
    height: 0 !important;
    width: 0 !important; // hiding scrollbar on chrome, safari & newer versions of opera
  }
`;

export const CollectionContainer = styled.div`
  position: relative;
`;

export const StackedScrollRow = styled.div`
  padding-top: ${sizing(16)};
  padding-bottom: ${sizing(16)};
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  column-gap: ${sizing(12)};

  overflow-x: scroll;
  ${utils.hideScrollbars};

  @media (max-width: ${media.queries.MOBILE}) {
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
  }

  @media (min-width: ${media.queries.DESKTOP}) {
    flex-wrap: nowrap;
    column-gap: ${sizing(16)};
    row-gap: ${sizing(16)};
  }
`;

export const UserStackedScrollRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;

  @media (min-width: ${media.queries.TABLET}) {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    width: 100%;
    flex-direction: row;
    overflow-x: scroll;
    scrollbar-width: none;
    overflow: -moz-scrollbars-none;
    position: relative;

    &::-webkit-scrollbar {
      height: 0 !important;
      width: 0 !important; // hiding scrollbar on chrome, safari & newer versions of opera
    }
  }
`;

const ArrowWrapper = styled.div`
  position: absolute;
  top: 50%;
  transition: opacity ${animations.transition.NORMAL}ms ease-in-out;
  transform: translateY(-50%);
  z-index: 1;

  > button {
    display: flex;
    margin: 0;
  }
`;

export const ArrowLeftWrapper = styled(ArrowWrapper)`
  left: 8px;
  @media screen and (max-width: ${media.queries.MOBILE}) {
    visibility: hidden;
    display: none;
  }
`;

export const ArrowRightWrapper = styled(ArrowWrapper)`
  right: 8px;
  @media screen and (max-width: ${media.queries.MOBILE}) {
    visibility: hidden;
    display: none;
  }
`;

export const ViewColumn = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: ${media.queries.TABLET}) {
    margin: 0 ${sizing(25)};
  }

  @media (min-width: 3500px) {
    margin: 0 ${sizing(25)};
  }
`;

export const C4BScrollRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 20px;
  margin-top: 20px;
  height: 250px;

  overflow-x: scroll;
  scrollbar-width: none;
  overflow: -moz-scrollbars-none;
  position: relative;

  &::-webkit-scrollbar {
    height: 0 !important;
    width: 0 !important; // hiding scrollbar on chrome, safari & newer versions of opera
  }

  & ${CardContainer} {
    margin: 0 !important;
    height: 100%;
  }

  & ${UserCardContainer} {
    margin: 0 !important;
    min-width: 248px;
    max-height: 248px;
    min-height: 210px;
    max-height: 210px;
  }
`;
