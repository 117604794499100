import { color } from 'design-system/style';
import * as React from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import useCarouselControls from '../VisibilityContext/hooks/useCarouselControls';
import {
  ScrollArrowContainer,
  StyledScrollArrowLeft,
  StyledScrollArrowRight,
} from './Styled';

type VariantType = 'primary' | 'secondary';
type ArrowProps = {
  onClick?: () => void;
  variant?: VariantType;
  addScrollRight?: boolean;
  addScrollLeft?: boolean;
};

const ArrowLeft = ({
  onClick,
  variant = 'primary',
  addScrollLeft,
}: ArrowProps) => {
  const { scrollLeft } = useCarouselControls();

  const handleOnclick = () => {
    if (addScrollLeft && scrollLeft) {
      scrollLeft();
    }
    onClick?.();
  };

  return (
    <StyledScrollArrowLeft
      aria-label="Previous"
      isDisabled={!scrollLeft}
      onClick={handleOnclick}
      variant={variant}
    >
      <ChevronLeft
        size={20}
        color={!scrollLeft ? color.solids.MUTED : color.solids.WHITE}
      />
    </StyledScrollArrowLeft>
  );
};

const ArrowRight = ({
  onClick,
  variant = 'primary',
  addScrollRight,
}: ArrowProps) => {
  const { scrollRight } = useCarouselControls();

  const handleOnclick = () => {
    if (addScrollRight && scrollRight) {
      scrollRight();
    }
    onClick?.();
  };

  return (
    <StyledScrollArrowRight
      aria-label="Next"
      isDisabled={!scrollRight}
      onClick={handleOnclick}
      variant={variant}
    >
      <ChevronRight
        size={20}
        color={!scrollRight ? color.solids.MUTED : color.solids.WHITE}
      />
    </StyledScrollArrowRight>
  );
};

type ScrollArrowProps = {
  onClick?: () => void;
  variant?: VariantType;
  addScrollLeft?: boolean;
  addScrollRight?: boolean;
};

export const ScrollArrowLeft = ({
  onClick,
  variant,
  addScrollLeft,
}: ScrollArrowProps) => {
  return (
    <ArrowLeft
      onClick={onClick}
      variant={variant}
      addScrollLeft={addScrollLeft}
    />
  );
};

export const ScrollArrowRight = ({
  onClick,
  variant,
  addScrollRight,
}: ScrollArrowProps) => {
  return (
    <ArrowRight
      onClick={onClick}
      variant={variant}
      addScrollRight={addScrollRight}
    />
  );
};

const ScrollArrows = ({
  onClickScrollLeft,
  onClickScrollRight,
}: {
  onClickScrollLeft: null | VoidFunction;
  onClickScrollRight: null | VoidFunction;
}) => {
  return (
    <ScrollArrowContainer>
      <ArrowLeft onClick={onClickScrollLeft} />
      <ArrowRight onClick={onClickScrollRight} />
    </ScrollArrowContainer>
  );
};

const MemoizedScrollArrows = React.memo(ScrollArrows);

export default () => {
  const { scrollLeft, scrollRight } = useCarouselControls();

  return (
    <MemoizedScrollArrows
      onClickScrollLeft={scrollLeft}
      onClickScrollRight={scrollRight}
    />
  );
};
