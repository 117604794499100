import isNil from 'lodash/isNil';
import * as React from 'react';
import findNextIndexes from './findNextIndexes';

const useNextElements = (props: {
  rowItemRefs: React.MutableRefObject<HTMLDivElement[]>;
  numItems: number;
  numElementsVisible: number;
  visibilityList: boolean[];
  scrollOption: ScrollIntoViewOptions;
}) => {
  const {
    rowItemRefs,
    numItems,
    numElementsVisible,
    visibilityList,
    scrollOption,
  } = props;

  // Derived data based on the current visibility state
  const [nextLeftElement, setNextLeftElement] =
    React.useState<HTMLDivElement | null>(null);
  const [nextRightElement, setNextRightElement] =
    React.useState<HTMLDivElement | null>(null);

  React.useEffect(() => {
    const scrollStep = numElementsVisible || 2;
    const { leftIndex, rightIndex } = findNextIndexes(
      numItems,
      visibilityList,
      scrollStep
    );

    const nextLeft =
      !isNil(leftIndex) && rowItemRefs.current[leftIndex] !== undefined
        ? rowItemRefs.current[leftIndex]
        : null;
    const nextRight =
      !isNil(rightIndex) && rowItemRefs.current[rightIndex] !== undefined
        ? rowItemRefs.current[rightIndex]
        : null;

    setNextLeftElement(nextLeft);
    setNextRightElement(nextRight);
  }, [visibilityList, numElementsVisible, numItems, rowItemRefs]);

  const scrollLeft = React.useCallback(
    () => nextLeftElement?.scrollIntoView(scrollOption),
    [nextLeftElement]
  );

  const scrollRight = React.useCallback(
    () => nextRightElement?.scrollIntoView(scrollOption),
    [nextRightElement]
  );

  return {
    scrollLeft,
    scrollRight,
    nextLeftElement,
    nextRightElement,
  };
};

export default useNextElements;
