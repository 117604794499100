import analytics from 'analytics';
import * as React from 'react';
import { DeepPartial } from 'redux';
import { ActionEventSchema } from 'types/src/utils/analytics';
import { VisibilityContext } from '..';

const getAnalyticsPayload = (
  rowLabelText: string,
  rowKey: string,
  rowIndex: number,
  direction: 'left' | 'right'
): DeepPartial<ActionEventSchema> => {
  return {
    eventName: 'Engage',
    eventDtl: { eventType: 'Click' },
    objectDtl: {
      objectType: 'Button',
      objectName: 'Scroll Arrows',
      objectLabel: direction,
    },
    positionDtl: {
      rowLabelText,
      rowKey,
      row: rowIndex,
    },
  };
};

export default () => {
  const visibilityContext = React.useContext(VisibilityContext);
  const { rowLabelText, rowKey, row } = visibilityContext.analyticsData;

  const onClickScrollLeft = React.useCallback(() => {
    visibilityContext.scrollLeft();

    const analyticsPayload = getAnalyticsPayload(
      rowLabelText,
      rowKey,
      row,
      'left'
    );
    analytics.trackAction(analyticsPayload);
  }, [visibilityContext.scrollLeft]);

  const onClickScrollRight = React.useCallback(() => {
    visibilityContext.scrollRight();

    const analyticsPayload = getAnalyticsPayload(
      rowLabelText,
      rowKey,
      row,
      'right'
    );
    analytics.trackAction(analyticsPayload);
  }, [visibilityContext.scrollRight]);

  return {
    scrollLeft: visibilityContext.nextLeftElement
      ? onClickScrollLeft
      : undefined,
    scrollRight: visibilityContext.nextRightElement
      ? onClickScrollRight
      : undefined,
  };
};
