import styled from 'styled-components';
import { Link } from 'react-router-dom';

const linkStyle = `
  text-decoration: none;
  cursor: pointer;

  &:hover,
  &:visited,
  &:focus,
  &:active {
    text-decoration: none;
  }

  &:focus {
    outline: ${({ theme }) => `5px auto ${theme.colors.pink}`};
    outline-offset: -2px;
  }
`;

export const StyledAnchor = styled.a`
  ${linkStyle}
`;

export const StyledLink = styled(Link)`
  ${linkStyle}
`;
