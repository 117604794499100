import styled from 'styled-components';

import { media, sizing } from 'design-system/style';

const CONTENT_WIDTH = '1600px';
export const BROWSE_GUTTER_LARGE = '54px';
export const BROWSE_GUTTER = '18px';

const STARCARD_LIST_EXPERIMENT_PAGE_WIDTH = '712px';

export const Container = styled.div<{
  isStarcardListExperimentEnabled: boolean;
}>`
  min-height: 100vh;
  padding: ${({ isStarcardListExperimentEnabled }) =>
    isStarcardListExperimentEnabled ? 0 : `${sizing(24)} 0 ${sizing(50)} 0`};
  overflow-x: hidden;
`;

export const Wrapper = styled.div<{
  isStarcardListExperimentEnabled: boolean;
  maxWidth?: string;
}>`
  display: block;
  overflow-x: hidden;
  padding: 0 ${BROWSE_GUTTER};
  width: 100%;

  @media screen and (min-width: ${media.queries.TABLET}) {
    margin: 0 auto;
    max-width: ${({ isStarcardListExperimentEnabled, maxWidth }) =>
      isStarcardListExperimentEnabled
        ? STARCARD_LIST_EXPERIMENT_PAGE_WIDTH
        : `calc(${maxWidth ?? CONTENT_WIDTH} + (${BROWSE_GUTTER_LARGE} * 2))`};
    padding: ${({ isStarcardListExperimentEnabled }) =>
      isStarcardListExperimentEnabled ? 0 : `0 ${BROWSE_GUTTER_LARGE}`};
  }
`;
