import * as React from 'react';
import { Box, BoxProps } from 'domains/web/components';
import { VisibilityContext } from '.';

// Wrapper component for registering row item refs with VisibilityContext
const WithItemVisibility = ({
  index,
  refCallback,
  children,
  style,
  ...boxProps
}: {
  index: number;
  children: React.ReactNode;
  style?: React.CSSProperties;
  refCallback: (ref: HTMLDivElement) => void;
} & BoxProps) => {
  return (
    <Box
      // @ts-ignore
      ref={refCallback}
      data-observer-index={index}
      style={style}
      scrollMargin="md"
      scrollSnapAlign="center"
      {...boxProps}
    >
      {children}
    </Box>
  );
};

const MemoizedWithItemVisibility = React.memo(WithItemVisibility);

export default (
  props: {
    index: number;
    children: React.ReactNode;
    style?: React.CSSProperties;
  } & BoxProps
) => {
  const { createObserverRefCallback } = React.useContext(VisibilityContext);

  const memoizedRefCallback = React.useCallback((ref) => {
    const refCallback = createObserverRefCallback(props.index);
    return refCallback(ref);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MemoizedWithItemVisibility {...props} refCallback={memoizedRefCallback} />
  );
};
