const findNextIndexes = (
  numItems: number,
  visibilityList: boolean[],
  scrollStep: number
): { leftIndex: number | null; rightIndex: number | null } => {
  if (numItems <= 1) {
    return { leftIndex: null, rightIndex: null };
  }
  // If the first/last items are not visible, we know we can scroll
  // in that direction.
  const canScrollLeft = !visibilityList[0];
  const canScrollRight = !visibilityList[numItems - 1];

  // Indexes for looping
  let leftIndex = 0;
  let rightIndex = numItems - 1;

  // null values are used to prevent iterating in a specific direction
  let nextLeftIndex = canScrollLeft ? undefined : null;
  let nextRightIndex = canScrollRight ? undefined : null;

  while (
    (nextLeftIndex === undefined || nextRightIndex === undefined) &&
    leftIndex < rightIndex
  ) {
    if (canScrollLeft) {
      // Left to right looking for [..., false, true, ...]
      if (!visibilityList[leftIndex] && !!visibilityList[leftIndex + 1]) {
        nextLeftIndex = leftIndex;

        if (nextLeftIndex < scrollStep) {
          // Skip to the first element if possible
          nextLeftIndex = 0;
        } else {
          nextLeftIndex -= scrollStep - 1;
        }
      }
    }

    if (canScrollRight) {
      // Right to left looking for [..., true, false, ...]
      if (!visibilityList[rightIndex] && !!visibilityList[rightIndex - 1]) {
        nextRightIndex = rightIndex;

        if (nextRightIndex > numItems - scrollStep) {
          // Skip to the last element if possible
          nextRightIndex = numItems - 1;
        } else {
          nextRightIndex += scrollStep - 1;
        }
      }
    }

    // Only increment the pointers in either direction if we're still searching
    if (nextLeftIndex === undefined) {
      leftIndex += 1;
    }
    if (nextRightIndex === undefined) {
      rightIndex -= 1;
    }
  }

  return {
    leftIndex: nextLeftIndex ?? null,
    rightIndex: nextRightIndex ?? null,
  };
};

export default findNextIndexes;
