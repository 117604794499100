import {
  STARCARD_DESKTOP_WIDTH,
  STARCARD_MOBILE_WIDTH,
} from 'components/StarCard/constants';
import { media, sizing } from 'design-system/style';
import styled from 'styled-components';

export const UserCardContainer = styled.div`
  flex: 1;
  max-width: ${sizing(STARCARD_MOBILE_WIDTH)};
  min-width: ${sizing(STARCARD_MOBILE_WIDTH)};

  @media screen and (min-width: ${media.queries.TABLET}) {
    max-width: ${sizing(STARCARD_DESKTOP_WIDTH)};
    min-width: ${sizing(STARCARD_DESKTOP_WIDTH)};
  }
`;
