import styled from 'styled-components';
import { sizing, media } from 'design-system/style';

export const CardContainer = styled.div<{
  isUserOrMerchScrollRow: boolean;
  isFirstItem: boolean;
}>`
  @media screen and (min-width: ${media.queries.TABLET}) {
    ${({ isUserOrMerchScrollRow }) =>
      isUserOrMerchScrollRow
        ? `margin: 0 ${sizing(16)} 0 ${sizing(16)};`
        : `margin: 0 ${sizing(9)} 0 ${sizing(9)};`}
    ${({ isFirstItem }) => isFirstItem && `margin-left: 0;`}
  }
  @media screen and (max-width: ${media.queries.TABLET}) {
    // TODO: once consumer_explore_tiles make a decision on this spacing changes
    margin-right: ${sizing(6)};
    margin-left: ${sizing(6)};
    ${({ isFirstItem, isUserOrMerchScrollRow }) => {
      if (isFirstItem) {
        if (isUserOrMerchScrollRow) {
          return `margin-left: ${sizing(16)};`;
        }
        return 'margin-left: 0;';
      }
      return '';
    }}
  }
`;
