import * as React from 'react';
import { Box } from 'domains/web/components';
import useIntersectionObserver from './hooks/useIntersectionObserver';
import useNextElements from './hooks/useNextElements';

export type SetRefCallback = (ref: HTMLDivElement) => void;

const Context = React.createContext<{
  scrollLeft: VoidFunction;
  scrollRight: VoidFunction;
  createObserverRefCallback: (index: number) => SetRefCallback;
  analyticsData: {
    rowLabelText: string;
    rowKey: string;
    row: number;
  };
  nextLeftElement: HTMLDivElement | null;
  nextRightElement: HTMLDivElement | null;
  currentPage: number;
  totalPages: number;
}>({
  scrollLeft: () => null,
  scrollRight: () => null,
  createObserverRefCallback: () => () => null,
  analyticsData: {
    rowLabelText: '',
    rowKey: '',
    row: -1,
  },
  nextLeftElement: null,
  nextRightElement: null,
  currentPage: 0,
  totalPages: 0,
});

const { Consumer, Provider } = Context;

export { Context as VisibilityContext, Consumer as VisibilityConsumer };

interface Props {
  numItems: number;
  children: React.ReactNode;
  analyticsData: {
    rowLabelText: string;
    rowKey: string;
    row: number;
  };
  scrollOption?: ScrollIntoViewOptions;
  onCarouselEndReached?: () => void;
  style?: React.CSSProperties;
}

const VisibilityProvider: React.FunctionComponent<Props> = ({
  children,
  numItems,
  analyticsData,
  scrollOption = {
    behavior: 'smooth',
    block: 'nearest',
    inline: 'nearest',
  },
  onCarouselEndReached,
  style,
}: Props) => {
  const rowRef = React.useRef();

  const {
    visibilityList,
    numElementsVisible,
    createObserverRefCallback,
    rowItemRefs,
  } = useIntersectionObserver(rowRef, onCarouselEndReached);

  let currentPage = 1;
  const totalPages = Math.ceil(visibilityList.length / numElementsVisible);
  for (let i = 0; i < numItems - 1; i += numElementsVisible) {
    if (visibilityList[i + 1]) {
      currentPage = Math.floor(i / numElementsVisible) + 1;
    }
  }
  currentPage = visibilityList[visibilityList.length - 1]
    ? totalPages
    : currentPage;

  const { scrollLeft, scrollRight, nextLeftElement, nextRightElement } =
    useNextElements({
      rowItemRefs,
      numItems,
      numElementsVisible,
      visibilityList,
      scrollOption,
    });

  return (
    <Box
      /**
       * ClassName needed to allow usages of this provider being able to target this Box.
       * Ideally, we should be able to simplify the legacy implementation of the carousel with its analytics.
       */
      className="provider-outer-box"
      ref={rowRef}
      style={style || {}}
      scrollSnapType="x proximity"
    >
      <Provider
        value={{
          scrollLeft,
          scrollRight,
          createObserverRefCallback,
          analyticsData,
          nextLeftElement,
          nextRightElement,
          currentPage,
          totalPages,
        }}
      >
        {children}
      </Provider>
    </Box>
  );
};

export default React.memo(VisibilityProvider);
