import {
  KIDS_STARCARD_DESKTOP_WIDTH,
  KIDS_STARCARD_LARGE_DESKTOP_WIDTH,
  STARCARD_DESKTOP_WIDTH,
} from 'components/StarCard/constants';
import { sizing, media } from 'design-system/style';
import styled from 'styled-components';

const KIDS_LARGE_DESKTOP_BREAKPOINT = '1708px';

// media query width determined using 164px starcard min-width
// calc((100vw - total horizontal padding - layout page horizontal padding / # of cards per row)
export const UserStackItem = styled.div<{
  isPlaceholder: boolean;
  isKidsStarcard?: boolean;
}>`
  ${({ isPlaceholder }) =>
    isPlaceholder &&
    `
      @media screen and (min-width: ${media.queries.TABLET}) {
        display: none;
      }
      height: 0;
      margin-bottom: 0 !important;
  `}

  @media screen and (max-width: ${media.queries.TABLET}) {
    flex: 1 0;
  }

  margin-top: ${sizing(16)};
  margin-left: ${sizing(4)};
  margin-right: ${sizing(4)};

  min-width: calc(100vw - 12px - 36px);
  max-width: calc(100vw - 12px - 36px);

  @media screen and (min-width: 344px) {
    min-width: calc((100vw - 16px - 36px) / 2);
    max-width: calc((100vw - 16px - 36px) / 2);
  }

  @media screen and (min-width: 516px) {
    min-width: calc((100vw - 24px - 36px) / 3);
    max-width: calc((100vw - 24px - 36px) / 3);
  }

  @media screen and (min-width: 688px) {
    min-width: calc((100vw - 32px - 36px) / 4);
    max-width: calc((100vw - 32px - 36px) / 4);
  }

  @media screen and (min-width: ${media.queries.TABLET}) {
    flex: none;
    min-width: ${({ isKidsStarcard }) =>
      sizing(
        isKidsStarcard ? KIDS_STARCARD_DESKTOP_WIDTH : STARCARD_DESKTOP_WIDTH
      )};
    max-width: ${({ isKidsStarcard }) =>
      sizing(
        isKidsStarcard ? KIDS_STARCARD_DESKTOP_WIDTH : STARCARD_DESKTOP_WIDTH
      )};
    margin-left: 0;
    margin-right: ${sizing(16)};
  }

  @media screen and (min-width: ${KIDS_LARGE_DESKTOP_BREAKPOINT}) {
    ${({ isKidsStarcard }) =>
      isKidsStarcard &&
      `min-width: ${sizing(KIDS_STARCARD_LARGE_DESKTOP_WIDTH)}`};
    ${({ isKidsStarcard }) =>
      isKidsStarcard &&
      `max-width: ${sizing(KIDS_STARCARD_LARGE_DESKTOP_WIDTH)}`};
  }
`;
