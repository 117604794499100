import React from 'react';
import {
  BrowseLink as BrowseLinkType,
  LinkType,
} from 'types/src/api/endpoints/explore/utils';
import Link, { Anchor } from 'components/Link';
import { History } from 'history';
import { useHistory } from 'react-router-dom';

const routeMap = Object.freeze({
  category: '/browse/',
  tag: '/tags/',
  oldBrowse: '/c/',
  search: '/search?q=',
  site: '/',
});

export const getLink = (linkType: LinkType, linkKey?: string) => {
  if (!routeMap[linkType]) return null;

  return `${routeMap[linkType]}${linkKey}`;
};

export const openBrowseLink = (
  { linkType, linkKey, externalUrl }: BrowseLinkType,
  {
    openInNewTab = false,
    history,
  }: { openInNewTab: boolean; history?: History }
) => {
  let link = getLink(linkType, linkKey);

  if (linkType === 'external') {
    link = externalUrl;
  }

  if (openInNewTab || linkType === 'external') {
    window.open(link, openInNewTab ? '_blank' : '_self');
  } else {
    history.push(link);
  }
};

type OwnProps = BrowseLinkType & {
  children: React.ReactNode;
  onClick?: () => void;
} & {
  [propName: string]: any; // to support the prop pass through to Link
  openInNewTab?: boolean;
};

const BrowseLink = (props: OwnProps) => {
  const history = useHistory();
  const {
    linkType,
    linkKey,
    children,
    openInNewTab = true,
    onClick,
    externalUrl,
    ...rest
  } = props;

  const handlePress = (e) => {
    e.preventDefault();
    if (onClick) {
      onClick();
    }

    openBrowseLink(
      { linkType, linkKey, externalUrl },
      { openInNewTab, history }
    );
  };

  if (linkType === 'external') {
    return (
      <Anchor
        target={openInNewTab ? '_blank' : '_self'}
        {...rest}
        onClick={handlePress}
        data-testid="external-link"
      >
        {children}
      </Anchor>
    );
  }

  const destination = getLink(linkType, linkKey);

  // If we can't build a link from this type, just return a div
  if (!linkType || !destination) {
    return (
      <div {...rest} data-testid="no-link">
        {children}
      </div>
    );
  }

  return (
    <Link
      target={openInNewTab ? '_blank' : '_self'}
      to={destination}
      {...rest}
      onClick={handlePress}
      data-testid="relative-link"
    >
      {children}
    </Link>
  );
};

export default React.memo(BrowseLink);
