import * as React from 'react';

const useIntersectionObserver = (
  rowRef: React.MutableRefObject<HTMLDivElement>,
  onCarouselEndReached?: () => void
) => {
  const [numElementsVisible, setNumElementsVisible] = React.useState<number>();
  const [visibilityList, setVisibilityList] = React.useState<boolean[]>([]);

  const [observer, setObserver] = React.useState<IntersectionObserver>();
  // Index based list of refs to row elements
  const rowItemRefs = React.useRef([] as HTMLDivElement[]);
  // If a ref callback is triggered before initialization, queue them up to be observed
  const toBeObserved = React.useRef([]);

  // Set up an Intersection Observer tracking all elements in the row.
  // This cannot happen server side so we store it in state instead of a ref.
  React.useEffect(() => {
    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      setVisibilityList((prevVisibilityList) => {
        const newVisibilityList = [...(prevVisibilityList || [])];

        let shouldUpdate = false;

        entries.forEach((entry) => {
          const attr = entry.target.attributes.getNamedItem(
            'data-observer-index'
          );
          const index = attr.value;
          const isVisible = entry.isIntersecting;

          if (newVisibilityList[index] !== isVisible) {
            shouldUpdate = true;
          }
          newVisibilityList[index] = isVisible;
        });

        if (!shouldUpdate) {
          // If nothing changed, opt out of updates
          return prevVisibilityList;
        }

        return newVisibilityList;
      });
    };

    setObserver(
      new IntersectionObserver(observerCallback, {
        root: rowRef.current,
        threshold: 0.9,
      })
    );

    return () => observer?.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Since we can't initialize the observer server side, it's possible our ref callbacks
  // might trigger before the observer is initialized. Once it has, call observe
  // from our cache
  React.useEffect(() => {
    if (observer) {
      toBeObserved.current.forEach((ref) => {
        observer.observe(ref);
      });
      toBeObserved.current = [];
    }
  }, [observer]);

  // Observe the initial number of elements that can fit on the screen
  // to use for scrollStep
  React.useEffect(() => {
    if (numElementsVisible === undefined && visibilityList?.length > 0) {
      const numVisible = visibilityList.filter(
        (itemIsVisible) => itemIsVisible
      ).length;
      if (numVisible > 0 && numVisible !== numElementsVisible) {
        setNumElementsVisible(numVisible);
      }
    }

    // callback for onCarouselEndReached
    if (onCarouselEndReached && visibilityList[visibilityList.length - 1])
      onCarouselEndReached();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibilityList]);

  // Sets up a higher-order function that returns a ref callback so we have access to
  // DOM nodes that we can call observe() on
  const createObserverRefCallback =
    (index: number) => (ref: HTMLDivElement) => {
      rowItemRefs.current[index] = ref;

      if (!observer) {
        // Prevent race condition between initializing the observer
        // and ref callbacks being triggered
        toBeObserved.current.push(ref);
      } else if (ref) {
        observer.observe(ref);
      }
    };

  return {
    createObserverRefCallback,
    rowItemRefs,
    observer,
    visibilityList,
    numElementsVisible,
  };
};

export default useIntersectionObserver;
